
.main-content {
  padding: 20px 20px;
  margin: 0 10%;
  background: #ffffff83;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 42%;
}

.section {
  padding: 0% 10px 0%;
  width: 100%;
  margin: 10px 0;
  display: flex;
  flex-direction: column;

  align-items: center;
}

.section.education {
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.languages-section {
  text-align: center;
  font-weight: bold;
}

.item {
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: justify;
  padding: 10px;
  justify-content: space-between;
  width: 100%;
  transition: transform 0.2s ease-in-out;
}

.item::before {
  content: '';
  position: absolute;
  opacity: 0;
  top: 0;
  left: -4px;
  height: 100%;
  width: 3px;
  background-color: #5CE1E6;
  transition: opacity 0.1s ease-in-out;
}

.item.clickable:hover::before {
  opacity: 1;
}

.item.clickable {
  cursor: pointer;
}

.item.clickable:hover {
  /* transform: translateX(7px); */
  animation: turnWhite 0.1s ease-in-out forwards;
}

a {
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

@keyframes turnWhite {
  from {
    background-color: #f0f0f000;
  }
  to {
    background-color: white;
  }
}

h3, h4 {
  text-align: left;
}

ul {
  padding-left: 18px;
}

.blackText {
  color: black;
}

.main-content p {
  line-height: 1.6;
}

@media screen and (max-width: 1000px) {
  .section.education {
    flex-direction: column;
    align-items: center;
  }
  .main-content {
    padding: 20px 20px;
    margin: 0 10%;
    background: #ffffff83;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
  }

  .item {
    width: 100%;
  }
}