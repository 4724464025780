.picture {
    border-radius: 50%;
    width: 100px;
    /* height: 100px; */
    object-fit: cover;
    /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.699); */
    margin-right: 20px;
    
    /* border-color: radial-gradient(circle at center, #112d4ed5, transparent) border-box; */
    /* background: linear-gradient(white, white) padding-box, 
                radial-gradient(circle at center, #ffffff, transparent) border-box; */
    /* border: 5px solid #1a4478; */
    /* filter: blur(0.4px); */
}

@media (prefers-reduced-motion: no-preference) {
    .picture {
        /* animation: picture-spin infinite 5s ease-in-out alternate; */
    }
}

.header {
    display: flex;
    align-items: center;
    padding: 20px 0 20px 0;
    margin: 0 0% 0 0%;  
    /* width: 100%; */
    /* background-color: rgba(115, 168, 212, 0.368) ; */
    /* colo: 0.5; */

    justify-content: center;
    /* padding: 4rem; */
    /* background-color: #4590AB; */
    /* padding-top: 3%;
    padding-bottom: 3%; */

    /* padding-left: 15%; */
    background: #18466c;
    /* box-shadow: 0 8px 8px rgba(0, 0, 0, 0.3); */

    /* #18466c */
    /* background: #112d4e3c; */
    /* background:radial-gradient(box at center, #112d4e46, transparent) border-box; */
        /* background:radial-gradient(circle at center, #0d2e5c9d, transparent) border-box; */


    /* background: radial-gradient( #112d4ef0 0%, transparent 50%) border-box;  */
    /* background:radial-gradient(circle at center, #112d4e46, transparent) border-box; */
}

@media screen and (max-width: 900px) {
    .header {
        flex-direction: column;
        padding-left: 0px;
        padding: 30px 0 20px;

        /* Removed redundant 'align-items: center;' and 'text-align: center;' as they are inherited */
    }

    .picture {
        margin-right: 0;
        margin-bottom: 5px;
    }

    .header-text {
        align-items: center; /* Assuming you need this for responsive design */
    }
}

@keyframes picture-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* .header-text {
    display: flex;
    flex-direction: column;
    text-align: left;
} */

/* .header-text h1, h3 {
    padding: 0;
    margin-bottom: 0;
} */

/* .header-text h3 {
    color: grey;
    font-weight: 500;
} */



