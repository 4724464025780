/* App.css */

.App {
  font-family: "DM Sans", sans-serif;
  /* min-height: 100vh; */

}

html {
  font-size: 16px; /* Base font size for desktop */
}

.main-content-area {
  width: 70%;
  /* height: fit-content; */
  /* display: flex; */
  flex-direction: column;
  /* height: 50%; */
  /* min-height: 100vh; */
}
.resume-content{
  display:flex;
  justify-content: center;
  align-content: center;
}
/* Large screen adjustments */
@media screen and (max-width: 768px) {
  .App {
      flex-direction: row;
  }
  .main-content-area {
    width: 100%;
  }
  html {
    font-size: 14px; /* Smaller base font size for mobile */
  }

}
