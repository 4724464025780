/* Sidebar.css */
.sidebar {
    background-color: #112D4E;
    padding: 2rem 3rem;
    box-sizing: border-box;
    /* transition: width 0.3s ease; */
    width: 30%;
    height: 100vh;
    position: fixed;
    overflow-y: auto;
    right: 0px;
    top: 0px;
    z-index: 1000; 
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
}

/* .sidebar:hover {
    width: 27%;
} */

.whiteText {
    color: white; /* Add white text color */
    /* margin-bottom: 0; */
}

p {
    color: #5CE1E6;
    /* width: 320px; */
    text-align: justify;
    font-size: 0.8rem;
    margin: 0;

  }
  

@media (max-width: 768px) {
    .sidebar {
        width: 100%;
        height: auto;
        position: static; /* Remove fixed positioning */
        padding: 1rem 2rem;

    }

    /* .sidebar:hover {
        width: 100%;} */
}
