.return-button {
    display: inline-block;
    padding: 10px 15px;
    margin: 20px 0;
    background-color: #4CAF50; /* Green background */
    color: white;
    border: none;
    border-radius: 4px;
    font-weight: bold;
    text-decoration: none; /* Removes underline from the link */
    text-align: center;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.return-button:hover, .return-button:focus {
    background-color: #45a049; /* Darker shade of green */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Optional: Adding a focus outline for accessibility */
.return-button:focus {
    outline: 2px solid #45a049;
    outline-offset: 2px;
}
